<template>
  <div v-if="Sentence">
    <p v-if="!boundedTextContainer" :id="`order-hint-${unique_id_sentence}`">
      <slot name="order"></slot>
      <template v-if="user && !user.is_student && showCognitiveLevel">
        <template v-if="cognitiveLeveLColor.length > 0">
          <span
            :class="{ verb: cognitiveLevel }"
            :id="`sentence-action-${unique_id_sentence}`"
            :style="{
              'background-color': cognitiveLeveLColor + ' !important',
              color: $lightOrDark(cognitiveLeveLColor)
                ? '#2b2a2a !important'
                : 'white !important',
            }"
            ><u>{{ String(Sentence.action).trim() }}</u></span
          >
        </template>
        <template v-else>
          <span
            :class="{ verb: cognitiveLevel }"
            :id="`sentence-action-${unique_id_sentence}`"
            ><u>{{ String(Sentence.action).trim() }}</u></span
          >
        </template>
        {{
          Sentence.full_sentence
            ? String(Sentence.full_sentence)
                .substr(String(Sentence.full_sentence).indexOf(" ") + 1)
                .trim() ==
              String(Sentence.full_sentence.trim().split(" ").shift())
              ? ""
              : String(Sentence.full_sentence).substr(
                  String(Sentence.full_sentence).indexOf(" ") + 1
                )
            : ""
        }}
      </template>
      <template v-else>
        <span>{{ Sentence.full_sentence }}</span>
      </template>
    </p>
    <p v-else :id="`order-hint-${unique_id_sentence}`">
      <template v-if="user && !user.is_student && showCognitiveLevel">
        <template v-if="!single_line">
          <slot name="order"></slot>
          <span
            :class="{ verb: cognitiveLevel }"
            :id="`sentence-action-${unique_id_sentence}`"
            ><u>{{ Sentence.action }} </u></span
          >
          <BoundedTextContainer
            :Text="fullSentenceText"
            :Large="Large"
            :tooltip_text="tooltipText"
          ></BoundedTextContainer>
        </template>
        <template v-else>
          <div
            class="single-line"
            @click="(e) => e.target.classList.toggle('single-line')"
          >
            <slot name="order"></slot>
            <span
              :class="{ verb: cognitiveLevel }"
              :id="`sentence-action-${unique_id_sentence}`"
              ><u>{{ Sentence.action }} </u></span
            >
            {{ fullSentenceText }}
          </div>
        </template>
      </template>
      <template v-else>
        <BoundedTextContainer
          :Text="Sentence.full_sentence"
          :Large="Large"
          :tooltip_text="tooltipText"
          :single_line="single_line"
        >
          <template v-slot:order>
            <slot name="order"></slot>
          </template>
        </BoundedTextContainer>
      </template>
    </p>
    <b-tooltip
      v-if="cognitiveLevel && user && !user.is_student && showCognitiveLevel"
      class="hint"
      variant="secondary"
      delay="300"
      :target="`sentence-action-${unique_id_sentence}`"
    >
      {{ $getVisibleNames("mesh.cognitivelevel", false, "Nivel Cognitivo") }}
      {{ cognitiveLevel.level }}
    </b-tooltip>
    <b-popover
      v-else-if="
        showMultipleCognitiveLevel.length > 0 &&
        user &&
        !user.is_student &&
        showCognitiveLevel
      "
      class="hint"
      variant="secondary"
      placement="top"
      triggers="hover"
      :target="`sentence-action-${unique_id_sentence}`"
    >
      <!-- delay="300" -->
      <template #title>Este verbo se encontró en:</template>
      <div
        v-for="(cognitive_level, index) in showMultipleCognitiveLevel"
        :key="'multiple-cognitive-level-' + unique_id_sentence + '-' + index"
      >
        {{ $getVisibleNames("mesh.cognitivelevel", false, "Nivel Cognitivo") }}
        {{ cognitive_level.level }}
      </div>
    </b-popover>
    <b-tooltip
      v-else-if="user && !user.is_student && showCognitiveLevel"
      class="hint"
      variant="secondary"
      delay="300"
      :target="`sentence-action-${unique_id_sentence}`"
      placement="top"
    >
      Verbo sin nivel cognitivo.
    </b-tooltip>
    <b-tooltip
      v-if="order_hint"
      variant="danger"
      delay="300"
      :target="`order-hint-${unique_id_sentence}`"
      placement="bottom"
    >
      Este
      {{ $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso") }}
      ya cuenta con
      {{ $getVisibleNames("mesh.competence", false, "Competencia") }} en ese
      orden.
    </b-tooltip>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { generateUniqueId } from "@/utils/utils";

export default {
  name: "SentenceContainer",
  components: {
    BoundedTextContainer: () =>
      import("@/components/reusable/BoundedTextContainer"),
  },
  props: {
    Sentence: {
      type: Object,
      required: true,
    },
    order_hint: {
      type: Boolean,
      default: false,
    },
    boundedTextContainer: {
      type: Boolean,
      default: false,
    },
    showColorCognitiveLevel: {
      type: Boolean,
      default: true,
    },
    showCognitiveLevel: {
      type: Boolean,
      default: true,
    },
    Large: {
      type: Number,
      default: 100,
    },
    single_line: {
      type: Boolean,
      default: false,
    },
    unique_id: {
      type: String,
    },
  },

  data() {
    return {
      unique_id_sentence:
        this.unique_id && this.unique_id.length > 0
          ? this.unique_id
          : this.Sentence.id + this.Sentence.full_sentence,
      tooltip_text: "",
    };
  },
  computed: {
    ...mapGetters({
      taxonomy: names.TAXONOMY,
      user: "getUser",
    }),
    fullSentenceText() {
      if (this.Sentence.full_sentence) {
        if (
          String(this.Sentence.full_sentence)
            .substr(String(this.Sentence.full_sentence).indexOf(" ") + 1)
            .trim() ==
          String(this.Sentence.full_sentence.trim().split(" ").shift())
        ) {
          return "";
        } else {
          return String(this.Sentence.full_sentence).substr(
            String(this.Sentence.full_sentence).indexOf(" ") + 1
          );
        }
      } else {
        return "";
      }
    },
    tooltipText() {
      if (this.Sentence.full_sentence) {
        if (
          String(this.Sentence.full_sentence)
            .substr(String(this.Sentence.full_sentence).indexOf(" ") + 1)
            .trim() ==
          String(this.Sentence.full_sentence.trim().split(" ").shift())
        ) {
          return "";
        } else {
          return String(this.Sentence.full_sentence);
        }
      } else {
        return "";
      }
    },
    showMultipleCognitiveLevel() {
      if (!this.taxonomy || !this.Sentence || !this.Sentence.action) return [];
      const cognitive_levels = this.taxonomy.cognitive_levels.filter(
        (cognitive_level) =>
          cognitive_level.verbs.some((verb) =>
            this.$equals(verb.name, this.Sentence.action)
          )
      );
      return cognitive_levels;
    },
    cognitiveLevel() {
      if (
        !this.taxonomy ||
        !this.Sentence ||
        !this.Sentence.verb
        //  && !this.Sentence.action
      )
        return null;
      // Desestructuración para mejorar la legibilidad
      const {
        verb,
        // , action
      } = this.Sentence;
      const { cognitive_levels } = this.taxonomy;
      // Función de utilidad para encontrar el nivel cognitivo
      const findCognitiveLevel = (predicate) =>
        cognitive_levels.find((cognitive_level) =>
          cognitive_level.verbs.some(predicate)
        );
      // Determinar el nivel cognitivo basado en el verbo o la acción
      let cognitive_level = null;
      // if (verb) {
      cognitive_level = findCognitiveLevel(({ id }) => id == verb);
      // }
      // else {
      //   cognitive_level = findCognitiveLevel(({ name }) =>
      //     this.$equals(name, action)
      //   );
      // }
      return cognitive_level;
    },
    cognitiveLeveLColor() {
      if (
        this.cognitiveLevel &&
        this.showColorCognitiveLevel &&
        this.user &&
        !this.user.is_student &&
        this.showCognitiveLevel
      ) {
        if (
          this.cognitiveLevel.color == "" ||
          this.$equals(this.cognitiveLevel.color, "#ffffff")
        )
          return "";
        return this.cognitiveLevel.color;
      } else if (
        this.showMultipleCognitiveLevel.length == 1 &&
        this.showColorCognitiveLevel &&
        this.user &&
        !this.user.is_student &&
        this.showCognitiveLevel
      ) {
        const color = this.showMultipleCognitiveLevel[0].color;
        if (color == "" || this.$equals(color, "#ffffff")) return "";
        return color;
      }
      return "";
    },
  },
  methods: {
    // hideTooltipLater() {
    //   setTimeout(() => {
    //     this.showTooltip = false;
    //   }, 3000);
    // },
  },
};
</script>

<style scoped>
.single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sentenceContainer {
  padding: 2px;
  top: 0;
  white-space: nowrap;
  font-size: 16px;
}

p {
  text-align: left;
  margin-left: 2px;
  padding-left: 10px;
  margin-right: 2px;
  padding-right: 10px;
  font-size: var(--secondary-font-size);
}

.verb {
  transition: ease-in-out 0.3s;
  border-radius: 3px;
  padding: 0.2em;
}

.verb:hover {
  font-weight: bold;
  /* color: #0d1525; */
  background-color: #ebebeb;
  /* padding: 0 5px; */
  transition: 0.2s;
  cursor: help;
}
@media print {
  .single-line {
    overflow-y: hidden !important; /* Permitir que el contenido sea visible */
    white-space: normal !important; /* Permitir que el contenido envuelva */
    text-overflow: initial !important;
  }
}
</style>